import { User } from "../domain/User";
import { UserRepository } from "../domain/UserRepository";
import { vectiAppApiUrl } from "../../Shared/infrastructure/Config";
import { APIRepository } from "../../Shared/infrastructure/APIRepository";
import { UserExtended } from "../domain/UserExtended";

export function createApiUserRepository(): UserRepository {
  return new UserAPIRepository();
}

export class UserAPIRepository extends APIRepository implements UserRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getUserData(): Promise<User> {
    //console.log("UserAPIRepository:getUserData")
    const user = this.doGet<User>("/api/users/me", null).catch(error => {
      // console.log("UserAPIRepository:getUserData", error);
      throw error;
    });
    return user;
  }

  getUserExtendedData(userId: string): Promise<UserExtended> {
    //console.log("UserAPIRepository:getUserExtendedData")
    const user = this.doGet<UserExtended>("/api/userext/" + userId);
    return user;
  }

  userUidDefaultCompany(_user: User): Promise<string> {
    console.log("user:  " + JSON.stringify(_user));
    return Promise.resolve("2");
  }
}
