<template>
  <v-app>
    <v-app-bar elevation="0" style="border-bottom: 0.1px solid grey">
      <v-toolbar color="#ffffff">
        <v-btn v-if="user_ex && isAuthenticated" :icon="`mdi-menu-${expand ? 'close' : 'open'}`" color="#1F2937"
          @click="changeExpandMenu" style="left: -5px">
        </v-btn>
        <v-hover disabled>
          <img src="https://www.vecti.cl/wp-content/uploads/2022/09/cropped-03-logo-superior-1.png" alt="Logo"
            width="90" class="ml-5" />
        </v-hover>
        <v-spacer></v-spacer>

        <v-btn v-if="user_ex && isAuthenticated">
          <ShowUserCompanies :listCompanies="user_ex.companies" :default_company="user_ex.default_company" />
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn color="blue-darken-2" size="small" class="ma-1" variant="elevated" v-if="!isAuthenticated && !isLoading"
          @click="login">
          Inicio Sesión
        </v-btn>

        <v-btn color="blue-darken-4" size="small" class="ma-1" variant="elevated" v-if="!isAuthenticated"
          @click="signup">
          Registrate
        </v-btn>

        <v-menu>
          <v-btn flat slot="activator" color="yellow">
            <v-icon icon="mdi-arrow-left" left></v-icon>
            <span>Menu</span>
          </v-btn>
        </v-menu>

        <v-btn color="green-darken-2" size="small" class="ma-1" variant="elevated"
          v-if="isAuthenticated && store.state.inUseCompany !== null">
          <v-icon icon="mdi-arrow-down" left></v-icon>Actualizar


          <v-menu activator="parent">
            <v-list>
              <v-list-item base-color="green-darken-3" size="small">
                <v-list-item-title @click="bank"
                  v-if="store.getters.getInUseCompanyHasAccount">Banco</v-list-item-title>
                <v-list-item-title @click="sii">SII</v-list-item-title>
                <v-list-item-title @click="conciliation"
                  v-if="store.getters.getInUseCompanyHasAccount">Conciliación</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-btn>


        <v-btn color="red-darken-2" size="small" class="ma-1" variant="elevated" v-if="isAuthenticated" @click="logout">
          Salir
        </v-btn>


      </v-toolbar>

      <!--
      <v-spacer></v-spacer>

      <button v-if="isAuthenticated && vecti_user">
        ESTA AUTENTICADO Y ES USUARIO
      </button>

      <div v-if="!isAuthenticated && !vecti_user">
        NO ESTA AUTENTICADO Y NO ES USUARIO
      </div>

      <div v-if="isAuthenticated && !vecti_user">
        ESTA AUTENTICADO Y NO ES USUARIO
      </div>
-->
      <!--
      <v-btn :loading="isLoading" variant="flat" rounded="lg" :color="!isAuthenticated ? 'info' : 'error'"
        @click="btnAction(isAuthenticated)">
        <span>{{ !isAuthenticated ? "Login" : "Logout" }} {{ isAuthenticated }}</span>
      </v-btn>
-->
    </v-app-bar>
    <!-- Componente SideBarMenu -->
    <SideBarMenu v-if="user_ex && isAuthenticated" />




    <v-main v-if="isAuthenticated && vecti_user" class="flex">
      <!-- Contenido principal -->
      <v-container>
        <Dialog />
        <Notification />
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-main v-else>
      <LandingPage />
    </v-main>

  </v-app>
</template>
<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { useSynchcronizeBank } from "./composables/useSynchronize";
export default {
  data() {

    return {
      //userExtended: "no user ext",
      //userExtended: "cargando",
    };
  },
  watch: {
    vecti_user(newUser) {
      this.checkConditions(newUser, this.isAuthenticated);
    },
    isAuthenticated(newAuth) {
      this.checkConditions(this.vecti_user, newAuth);
    },

  },
  mounted() {
    // console.log("this.isAuthenticated : " + JSON.stringify(this.isAuthenticated));
    // console.log("this.vecti_user : " + JSON.stringify(this.vecti_user?.message));
    if (this.isAuthenticated && this.vecti_user?.message === "Not Found") {
      // console.log("redireccionando a welcome");
      this.$router.push('/welcome');
    }
  },
  methods: {
    checkConditions(user, auth) {
      // console.log("user: " + JSON.stringify(user));
      // console.log("auth: " + JSON.stringify(auth));
      if (auth && user?.message === "Not Found") {
        //console.log("redireccionando a welcome");
        this.$router.push('/welcome');
      }
    }
  },
  setup() {

    const { loginWithPopup, logout, user, isAuthenticated, isLoading } =
      useAuth0();
    const vecti_user = useUser();
    //console.log("vecti_user:" + JSON.stringify(vecti_user));
    const user_ex = useUserExtended(vecti_user);
    const expand = ref(store.getters.getExpandMenu);
    const btnAction = (bool) => {
      if (!bool) {
        loginWithPopup();
      } else {
        logout();
      }
    };

    const bank = async () => {
      store.dispatch("openNotification", { message: "Actualizando información bancaria", duration: 1500 });
      try {
        console.log("Sincronizando información bancaria: " + store.state.inUseCompany);
        const vectiResult = await useSynchcronizeBank(store.state.inUseCompany);
        console.log("vectiResult: " + JSON.stringify(vectiResult));

        //store.dispatch("updateMenuSelection");
        if (vectiResult) {
          store.dispatch("openNotification", { message: "Sincronización bancaria exitosa", duration: 1500 });
          store.commit("setReloadMenu", true);
        } else {
          store.dispatch("openNotification", { message: "Error en la sincronizacion bancaria", duration: 1500 });
        }
      } catch (e) {
        console.log("Error en sincronización bancaria: " + e);
        store.dispatch("openNotification", { message: "Error en la sincronizacion bancaria:" + e, duration: 1500 });
      }

    };

    const sii = async () => {
      store.dispatch("openNotification", { message: "Actualizando información del SII", duration: 1500 });
      try {
        console.log("Sincronizando información del SII: " + store.state.inUseCompany);
        const vectiResult = await useSynchcronizeSII(store.state.inUseCompany);
        console.log("vectiResult: " + JSON.stringify(vectiResult));
        if (vectiResult) {
          store.dispatch("openNotification", { message: "Sincronización SII exitosa", duration: 1500 });
          console.log("setUpdateSII prev: " + store.state.updateSII);
          store.commit("setUpdateSII", true);
          console.log("setUpdateSII after: " + store.state.updateSII);

        } else {
          store.dispatch("openNotification", { message: "Error en la sincronizacion del SII", duration: 1500 });
        }
      } catch (e) {
        console.log("Error en sincronización del SII: " + e);
        store.dispatch("openNotification", { message: "Error en la sincronizacion del SII:" + e, duration: 1500 });
      }
    };


    const conciliation = async () => {
      store.dispatch("openNotification", { message: "Actualizando conciliacion automática", duration: 1500 });
      try {
        console.log("Actualizando información de conciliacion: " + store.state.inUseCompany);
        const vectiResult = await useSynchcronizeAutoconciliation(store.state.inUseCompany);
        console.log("vectiResult: " + JSON.stringify(vectiResult));
        if (vectiResult) {
          store.dispatch("openNotification", { message: "Actualización de conciliación exitosa", duration: 1500 });
        } else {
          store.dispatch("openNotification", { message: "Error en la actualizacion de conciliacion", duration: 1500 });
        }
      } catch (e) {
        console.log("Error en sincronización del SII: " + e);
        store.dispatch("openNotification", { message: "Error en la actualizacion de conciliacion:" + e, duration: 1500 });
      }

    };


    const changeExpandMenu = () => {
      expand.value = !expand.value;
      store.commit("setExpandMenu", expand.value);
    };

    return {
      login: () => {
        loginWithPopup().then((res) => {
          useUser();
        });
      },
      signup: () => {
        loginWithPopup({ authorizationParams: { screen_hint: 'signup' } }).then((res) => {
          useUser();
        });
      },
      user,
      btnAction,
      isAuthenticated,
      isLoading,
      logout: () => {
        logout();
        vecti_user.value = null;
        user_ex.value = null;
      },
      vecti_user,
      user_ex,
      changeExpandMenu,
      expand,
      bank,
      sii,
      conciliation
    };
  },
};
</script>
