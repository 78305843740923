<template>

  <v-navigation-drawer width="300" v-model="drawer" :rail="expand" permanent
    style="background-color: #1f2937; color: white">
    <div v-if="store.getters.getInUseCompanyHasAccount">
      <div v-for="(item, idx) in menuItems" :key="idx">
        <v-list density="compact" nav :opened="opened" @update:opened="newOpened => opened = newOpened.slice(-1)">
          <v-list-item v-if="!item.subSec" :prepend-icon="`mdi-${item.iconName}`" :title="item.name" :to="item.to"
            @click="selections(item.to)" rounded="lg">
          </v-list-item>

          <v-list-group v-else fluid color="orange">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" :title="item.name" :prepend-icon="`mdi-${item.iconName}`"
                @click="selections(item.to)"></v-list-item>
            </template>
            <div v-for="(subItem, idy) in item.items" :key="idy">
              <v-list-item :title="subItem.name" :prepend-icon="`mdi-${subItem.iconName}`" :value="subItem.name"
                :to="subItem.to" @click="selections(subItem.to)" rounded="lg"></v-list-item>
            </div>
          </v-list-group>
        </v-list>
      </div>
    </div>
    <div v-else>
      <div v-if="store.getters.getInUseCompanyHasAccount === null">
        <v-list dense nav>
          <v-list-item title="Cargando..." rounded="lg">
          </v-list-item>
        </v-list>
      </div>
      <div v-else>
        <v-list density="compact" nav>
          <v-list-item title="Agregar Cuenta" prepend-icon="mdi-account-plus" value="TEST" @click="addBank"
            rounded="lg">
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>

</template>

<script>

export default {
  setup() {

    const currentKey = ref(0);
    const windowWidth = ref(window.innerWidth);
    const getMenuSelection = ref(store.getters.getMenuSelection);
    const sidebarOpen = ref(true);
    const opened = ref([]);

    const getConfig = ({ accounts }) => {
      return {
        agregarCuenta: !accounts || accounts.length === 0,
      };
    }
    const getMenuItems = (config) => {
      return [
        { name: "Inicio", iconName: "home-circle", subSec: false, to: "/" },
        //  (!config.agregarCuenta) &&
        {
          name: "Movimientos Bancarios",
          iconName: "bank",
          subSec: true,
          isOpen: false,
          items: [
            { name: "Movimientos pendientes", iconName: "cash-fast", to: "/movements-all" },
            //      { name: "Todos los movimientos OLD", iconName: "cash-fast", to: "/movementsAll/" },
            { name: "Traer movimientos", iconName: "cash-check", to: "/synchronizations/movements" },
            //      { name: "Sin Conciliar", iconName: "cash-remove", to: "/movements" },
            { name: "Conciliados 100%", iconName: "cash-check", to: "/reconciliations" },
            { name: "Conciliados Automáticos", iconName: "cash-check", to: "/autoreconciliations" },

          ],
        },
        //   (!config.agregarCuenta) &&
        {
          name: "Facturas, boletas y otros",
          iconName: "invoice-text-multiple-outline",
          subSec: true,
          isOpen: false,
          items: [
            { name: "Todos los documentos", iconName: "cash-fast", to: "/documents" },
            //     { name: "Ingresos (Ventas)", iconName: "cash-plus", to: "/incomes" },
            //    { name: "Egresos (Compras)", iconName: "cash-minus", to: "/outcomes" },
            { name: "Traer documentos tributarios", iconName: "cash-plus", to: "/synchronizations/sii" },

            { name: "Conciliados 100%", iconName: "cash-check", to: "/outinreconciliations" },
          ],
        },
        {
          name: "Conciliaciones",
          iconName: "invoice-text-multiple-outline",
          subSec: true,
          isOpen: false,
          items: [
            { name: "Efectuar Autoconciliacion", iconName: "cash-plus", to: "/synchronizations/autoreconciliations" },

          ],
        },

        {
          name: "Socios Comerciales",
          iconName: "briefcase-account",
          subSec: true,
          isOpen: false,
          items: [
            { name: "Clientes", iconName: "account-tie", to: "/businesspartners/clients" },
            { name: "Proveedores", iconName: "account-group-outline", to: "/businesspartners/providers" },
          ],
        },

        // (!config.agregarCuenta) &&
        {
          name: "Registro de Gastos",
          iconName: "application-edit-outline",
          subSec: true,
          isOpen: false,
          items: [
            { name: "Impuestos", iconName: "book-edit-outline", to: "/expenses/createExpense" },
            { name: "Honorarios", iconName: "badge-account-outline", to: "/expenses/createWage" },
          ],
        },
        {
          name: "Configuración",
          iconName: "cog",
          subSec: true,
          isOpen: false,
          items: [
            { name: "Mi Compañia", iconName: "inbox", to: "/company" },
            { name: "Mis cuentas", iconName: "account-details", to: "/accounts" },
            { name: "Mis accesos", iconName: "edit-settings-24-filled", to: "/access" },
            (config.agregarCuenta) && {
              name: "Agregar cuenta",
              iconName: "account-plus",
              method: addBank,
            }

          ],
        },
        //   { name: "Test", iconName: "test-tube-empty", subSec: false, to: "/test" },
      ]
    }

    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };


    const reloadComponent = (to) => {
      console.log("reloadComponent:" + to);
      console.log("currentKey:" + currentKey.value);
      console.log("route.path:" + route.path);
      /*
            if (route.path === to) {
              // Si ya estamos en la ruta, forzamos la recarga de la misma
              currentKey.value++;
            } else {
              // Navegamos normalmente si no es la misma ruta
              //router.push(to).catch(() => { });
            }
              */
    }

    const resetSelection = () => {
      store.dispatch("resetMenuSelection");
    };

    const selections = (url) => {
      console.log("seleccion: " + url);

      resetSelection();
      console.log("url: " + url);
      switch (url) {
        case "/movements-all":
          console.log("all movements");
          getMenuSelection.value.movementsAll = true;
          store.commit("setMenuSelection", getMenuSelection);
          store.commit("setReloadMenu", true);
          break;
        case "/movementsAll":
          console.log("all movements old");
          getMenuSelection.value.movementsAllOLD = true;
          store.commit("setMenuSelection", getMenuSelection);
          break;

        case "/movements":
          console.log("movements");
          getMenuSelection.value.movements = true;
          store.commit("setMenuSelection", getMenuSelection);
          break;
        case "/company":
          getMenuSelection.value.company = true;
          store.commit("setMenuSelection", getMenuSelection);
          break;
        case "/invoices":
          getMenuSelection.value.invoices = true;
          store.commit("setMenuSelection", getMenuSelection);
          break;
        case "/documents":
          getMenuSelection.value.documents = true;
          store.commit("setMenuSelection", getMenuSelection);
          break;
        case "/outcomes":
          getMenuSelection.value.outcomes = true;
          store.commit("setMenuSelection", getMenuSelection);
          break;
        case "/incomes":
          getMenuSelection.value.incomes = true;
          store.commit("setMenuSelection", getMenuSelection);
          break;
        case "/reconciliations":
          getMenuSelection.value.reconciliations = true;
          store.commit("setMenuSelection", getMenuSelection);
          break;

        default:
          break;
      }
    };

    const drawer = ref(true);
    let accountIsSetup = ref(false);
    const accounts = store.getters.getUserCompanies.get(store.getters.getInUseCompany);
    console.log("accounts en la carga:" + store.getters.getInUseCompany + " " + JSON.stringify(accounts));
    console.log("tiene cuenta?:" + JSON.stringify(accountIsSetup));
    console.log("getMenuSelection:" + JSON.stringify(store.getters.getMenuSelection));


    const menuItems = ref(getMenuItems(getConfig({ accounts })));
    const expand = ref(store.getters.getExpandMenu);

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
      if (window.innerWidth < 800) {
        store.commit("setExpandMenu", true);
      }
    });
    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });
    watch(
      () => accountIsSetup,
      (newValue) => {
        console.log("*******************accountIsSetup:" + accountIsSetup);
      }

    );

    watch(
      () => store.getters.getExpandMenu,
      (newValue) => {
        console.log("cambio de menu:" + newValue);
        expand.value = newValue;
      }
    );
    watch(
      () => store.getters.getInUseCompany,
      (newValue) => {
        console.log("cambio de compañia: " + newValue);
        store.commit("setReloadMenu", true);
        console.log("reload menu: ");
        const accounts = store.getters.getUserCompanies.get(newValue);
        console.log("newValue:" + JSON.stringify(accounts));
        if (!accounts || accounts.length === 0) {
          console.log("Muestra Agregar Cuenta")
          menuItems.value = getMenuItems({ agregarCuenta: true });
          console.log("store.getters.getInUseCompanyHasAccount:" + store.getters.getInUseCompanyHasAccount);
          store.commit("setInUseCompanyHasAccount", false);
          accountIsSetup.value = false;
        } else {
          console.log("Oculta Agregar Cuenta")
          menuItems.value = getMenuItems({ agregarCuenta: false });
          //  store.commit("setDataSetup", { taxAgency: true, account: true });
          console.log("store.getters.getInUseCompanyHasAccount: " + store.getters.getInUseCompanyHasAccount);
          store.commit("setInUseCompanyHasAccount", true);
          //store.getters.getInUseCompanyHasAccount.set(false);
          accountIsSetup.value = true;
        }
      }
    )
    watch(windowWidth, (newWidth, oldWidth) => {
      console.log("el menu está:" + expand.value);

      if (newWidth < 800 && !expand.value) {
        store.commit("setExpandMenu", true);
      } else if (newWidth >= 800 && expand.value) {
        store.commit("setExpandMenu", false);
      }
    });

    return {
      sidebarOpen,
      opened,
      menuItems,
      addBank,
      drawer,
      expand,
      getMenuSelection,
      selections,
      resetSelection,
      accountIsSetup,
      windowWidth,
      reloadComponent,
      currentKey
    };
  },
};
</script>